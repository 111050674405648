import React, { useContext, useState } from 'react';
import { Credentials } from 'realm-web';
import { useNavigate } from 'react-router-dom';
import { AppContext, User } from '../../App';
import { Container, Form, Wrapper, BrandLogo, Error } from '../../styles';
import { SubmitButton } from '../ResetPassword/styles';
import { Input } from './styles';

const SignIn: React.FC = () => {
  const { app, setUser } = useContext(AppContext);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  if (!app) {
    return <Container />;
  }

  return (
    <Wrapper>
      <BrandLogo>
        <svg
          width="118"
          height="105"
          viewBox="0 0 118 105"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="117" height="104.606" fill="url(#pattern0_13811_25198)" />
        </svg>
      </BrandLogo>
      <Container>
        <h1>Admin Sign In</h1>
        <Form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
            setError('');
            const target = e.target as typeof e.target & {
              email: { value: string };
              password: { value: string };
            };
            const email = target.email.value;
            const password = target.password.value;
            const credentials = Credentials.emailPassword(email, password);
            app
              ?.logIn(credentials)
              .then((user: User) => {
                user
                  .callFunction('utils/authorizeAdminUser')
                  .then(() => {
                    setUser(user);
                    navigate('/admin/portal');
                  })
                  .catch((e: any) => {
                    setError(e?.error?.message || e?.error);
                    user.logOut();
                  });
              })
              .catch((e: any) => {
                setError(e?.error);
              });
          }}>
          {error && <Error>{error}</Error>}
          <Input placeholder="Email" type="email" name="email" required />
          <Input placeholder="Password" type="password" name="password" required />
          <SubmitButton type="submit" value="Log in" />
        </Form>
      </Container>
    </Wrapper>
  );
};

export { SignIn };
