import styled from 'styled-components';

// Styled Components
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #4c0f0a, #1e1e1e);
  color: white;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  position: relative;
`;

export const BrandLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px;
`;

export const Container = styled.div`
  padding: 40px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 1000px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Input = styled.input`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 16px;
  outline: none;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const MenuItem = styled.button`
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-weight: 600;
  border-radius: 8px;
  transition: background 0.3s;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const SidePanel = styled.div`
  position: fixed;
  left: 0; /* Change to 'right: 0' for right-side positioning */
  top: 0;
  width: 300px; /* Adjust width as needed */
  height: 100vh;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubmitButton = styled.button`
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  background: #ff7f50;
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #ff5722;
  }
`;

export const Error = styled.div`
  color: #ff7f50;
  font-weight: 600;
  margin-bottom: 10px;
`;
