import React, { useContext } from 'react';
import { AppContext } from '../../App';
import ChatMessenger from '../../components/Messenger';
const AiMessenger: React.FC = () => {
  const { user } = useContext(AppContext);

  return <ChatMessenger userId={user?.id} />;
};

export { AiMessenger };
