import React, { useEffect, useState } from 'react';
import { App as RealmApp } from 'realm-web';
import Router from './router';
import { useLocation, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize('G-LCMH8Q1GTE');

export type User = Realm.User<
  Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory,
  SimpleObject,
  Realm.DefaultUserProfileData
>;
interface IContextProps {
  email: string;
  setEmail: (email: string) => void;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  app: Realm.App | null;
}

export const AppContext = React.createContext<IContextProps>({
  email: '',
  setEmail: () => undefined,
  user: null,
  setUser: () => undefined,
  app: null
});

function App() {
  const [email, setEmail] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [appId, setAppId] = useState<string | null>(null);
  const [app, setApp] = useState<Realm.App | null>(null);
  const [user, setUser] = React.useState<User | null>(app ? app.currentUser : null);

  const location = useLocation();

  const url = window.location.href;
  const pathname = window.location.pathname;

  useEffect(() => {
    console.log(location.pathname + location.search);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (url.includes('theguiderapp.com') && pathname === '/') {
      window.location.href = 'https://niyyah.app';
    }
  }, [pathname, url]);

  useEffect(() => {
    if (searchParams.get('appId') == null) {
      console.error('No App Id');
    } else {
      const applicationId = searchParams.get('appId');
      if (applicationId !== null) {
        setApp(new RealmApp({ id: applicationId }));
        setAppId(applicationId);
      }
    }
  }, [searchParams]);
  return (
    <AppContext.Provider value={{ email, setEmail, user, setUser, app }}>
      <Router />
    </AppContext.Provider>
  );
}

export default App;
