import { useEffect, useState } from 'react';
import {
  ChatContainer,
  Container,
  Form,
  TextArea,
  MessageBubble,
  MessageRow,
  SubmitButton,
  Wrapper,
  ErrorComponent
} from './styles';
import { Select } from '../../pages/Admin/styles';

// -----------------------
//     Type Definitions
// -----------------------
export interface Message {
  sender: 'user' | 'bot';
  text: string;
}

export interface ChatMessengerProps {
  userId?: string | null;
}

const BACKEND_URL = 'https://internal.guiderbackend.com';

const defaultPrompt = `You are SheikhGPT, a multilingual Islamic bot designed to answer Islam-related questions with accuracy and depth. Fluent in Arabic, Bahasa, Bosnian, French, Turkish, Urdu, and more, you craft precise, evidence-based responses exclusively from the Sunni tradition. 
        
        This is the history of our conversation so far, you are "Assistant":

        {history}

        Now here's my Question: {question}

        Answer the question based on the following context and the history of our conversation:
        {context}
        `;

/**
 * ChatMessenger Component (TypeScript version)
 *
 * Receives messages as a prop. Calls back to `onSend` and optionally `onBotReply`.
 */
export default function ChatMessenger({ userId = null }: ChatMessengerProps) {
  // Chat messages state
  const [messages, setMessages] = useState<Message[]>([]);

  // Form states
  const [systemPrompt, setSystemPrompt] = useState<string>(() => {
    return localStorage.getItem('systemPrompt') || defaultPrompt;
  });

  const [question, setQuestion] = useState<string>('What does the text say?');
  const [llmName, setLlmName] = useState<'Anthropic' | 'OpenAI'>('Anthropic');

  useEffect(() => {
    localStorage.setItem('systemPrompt', systemPrompt);
  }, [systemPrompt]);

  // Any error from the server
  const [error, setError] = useState<string>('');
  const [isIngesting, setIsIngesting] = useState<boolean>(false);

  const handleIngest = async () => {
    if (isIngesting) return;
    setIsIngesting(true);
    const response = await fetch(`${BACKEND_URL}/ingest`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    console.log(data);
    setIsIngesting(false);
    alert(
      'Ingestion in progress. Please wait for about 10 minutes before sending your question. Here are the files ingested: ' +
        JSON.stringify(data)
    );
  };

  const handleReset = async () => {
    setSystemPrompt(defaultPrompt);
  };

  /**
   * Send the user's question to the server (POST request).
   * The body format:
   * {
   *   "conversationId": "1234",
   *   "question": "...",
   *   "systemPrompt": "...",
   *   "llmName": "Anthropic" (or "OpenAI")
   * }
   */
  const handleSend = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    // 1) Display user question in chat
    if (question.trim() === '') return;
    setMessages((prev) => [...prev, { sender: 'user', text: question.trim() }]);

    try {
      // 2) Make the POST request
      const response = await fetch(`${BACKEND_URL}/test`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversationId: userId ?? '1234',
          question: question.trim(),
          systemPrompt: systemPrompt.trim(),
          llmName: llmName
        })
      });

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      // 3) Assume server returns JSON with the property "answer" or similar
      const data = await response.json();

      // For this demo, let's assume the response has { answer: string }
      const botReply = data.answer || JSON.stringify(data);

      // 4) Display bot reply in chat
      setMessages((prev) => [...prev, { sender: 'bot', text: botReply }]);
    } catch (err: any) {
      console.error('Error during POST:', err);
      setError(err.message || 'An error occurred while sending your question.');
    }
  };

  const ingestButtonText = isIngesting ? 'Ingesting... Please wait..' : 'Ingest';
  const resetButtonText = 'Reset Prompt';
  return (
    <Wrapper>
      <Container>
        <ChatContainer>
          {messages.map((msg, index) => (
            <MessageRow key={index} isUser={msg.sender === 'user'}>
              <MessageBubble isUser={msg.sender === 'user'}>{msg.text}</MessageBubble>
            </MessageRow>
          ))}
        </ChatContainer>

        <Form onSubmit={handleSend}>
          {error && <ErrorComponent>{error}</ErrorComponent>}

          <label htmlFor="systemPrompt" style={{ textAlign: 'left' }}>
            System Prompt:
          </label>
          <TextArea
            id="systemPrompt"
            rows={3}
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
          />

          <label htmlFor="question" style={{ textAlign: 'left' }}>
            Question:
          </label>
          <TextArea
            id="question"
            rows={2}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />

          <label htmlFor="llmName" style={{ textAlign: 'left' }}>
            Choose LLM:
          </label>
          <Select
            id="llmName"
            value={llmName}
            onChange={(e) => setLlmName(e.target.value as 'Anthropic' | 'OpenAI')}>
            <option value="Anthropic">Anthropic</option>
            <option value="OpenAI">OpenAI</option>
          </Select>

          <SubmitButton type="submit">Send</SubmitButton>
        </Form>
        <SubmitButton onClick={handleIngest}>{ingestButtonText}</SubmitButton>
        <SubmitButton onClick={handleReset}>{resetButtonText}</SubmitButton>
      </Container>
    </Wrapper>
  );
}
