import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Form, Input, Error } from '../../styles';
import { SubmitInput, Success, Select } from './styles';
const CreateCode: React.FC = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleYesClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError('');
    const target = e.target as typeof e.target & {
      extensionType: { value: string };
      codes: { value: number };
    };
    const extensionType = target.extensionType.value;
    const codes = target.codes.value;
    if (!codes) {
      alert('Invalid input for codes generator');
      return;
    }
    if (user != null)
      extensionType !== '' &&
        user
          .callFunction('referralsAndPromoCodes/generateAndSaveGuiderCodes', {
            extensionType,
            numOfCodes: Number(codes) > 0 ? Number(codes) : undefined
          })
          .then((res) => {
            console.log(res);
            console.log(JSON.stringify(res));
            setSuccess(`Successfully created code/s:\n${JSON.stringify(res)}`);
          })
          .catch((e: any) => {
            setError(e?.error);
          });
    else navigate('/admin/login');
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleNoClick = () => {};

  const handleAlert = (e: React.SyntheticEvent) => {
    const confirmed = window.confirm('Are you sure you want to create codes?');
    if (confirmed) {
      handleYesClick(e);
    } else {
      handleNoClick();
    }
  };
  return (
    <Form onSubmit={handleAlert}>
      <Select id="cars" name="extensionType">
        <option value="WEEKLY">Weekly</option>
        <option value="MONTHLY">Monthly</option>
        <option value="ANNUALLY">Annually</option>
      </Select>
      <Input placeholder="Number of codes" type="number" name="codes" />
      <SubmitInput type="submit" value="Generate" />
      {error.length > 0 && <Error>{error}</Error>}
      {success.length > 0 && <Success>{success}</Success>}
    </Form>
  );
};

export { CreateCode };
