import React, { useContext, useState } from 'react';
import { ScheduleNotification } from './ScheduleNotification';
import { EditNotifications } from './EditNotifications';
import { CreateCode } from './CreateCode';
import { CreatePromo } from './CreatePromo';
import { CodeRedemptions } from './CodeRedemptions';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { UnscheduleNotification } from './UnscheduleNotification';
import { TestDiversifiedNotifications } from './TestDiversifiedNotifications';
import { MobileConfigViewer } from './MobileConfigViewer';
import { BrandLogo, Container, MenuItem, SidePanel, Wrapper } from '../../styles';
import { Content } from './styles';
import { AiMessenger } from './AiMessenger';

const ACTIONS = {
  GIFT: 'GIFT',
  AI_MESSENGER: 'AI_MESSENGER',
  PROMO: 'PROMO',
  NOTIFICATION: 'NOTIFICATION',
  MOBILE_CONFIG: 'MOBILE_CONFIG',
  EDIT_NOTIFICATIONS: 'EDIT_NOTIFICATIONS',
  CHECK_REDEMPTIONS: 'CHECK_REDEMPTIONS',
  TEST_DIVERSIFIED_NOTIFICATIONS: 'TEST_DIVERSIFIED_NOTIFICATIONS',
  UNSCHEDULE_NOTIFICATION: 'UNSCHEDULE_NOTIFICATION',
  LOGOUT: 'LOGOUT'
};

const AdminPortal: React.FC = () => {
  const [currentAction, setCurrentAction] = useState<string>(ACTIONS.GIFT);
  const { app } = useContext(AppContext);
  const navigate = useNavigate();

  if (!app) {
    return <Container />;
  }

  const renderContent = () => {
    switch (currentAction) {
      case ACTIONS.GIFT:
        return <CreateCode />;
      case ACTIONS.AI_MESSENGER:
        return <AiMessenger />;
      case ACTIONS.PROMO:
        return <CreatePromo />;
      case ACTIONS.CHECK_REDEMPTIONS:
        return <CodeRedemptions />;
      case ACTIONS.TEST_DIVERSIFIED_NOTIFICATIONS:
        return <TestDiversifiedNotifications />;
      case ACTIONS.NOTIFICATION:
        return <ScheduleNotification />;
      case ACTIONS.MOBILE_CONFIG:
        return <MobileConfigViewer />;
      case ACTIONS.EDIT_NOTIFICATIONS:
        return <EditNotifications />;
      case ACTIONS.UNSCHEDULE_NOTIFICATION:
        return <UnscheduleNotification />;
      default:
        return <CreateCode />;
    }
  };

  return (
    <Wrapper>
      <BrandLogo>
        <svg
          width="118"
          height="105"
          viewBox="0 0 118 105"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="117" height="104.606" fill="url(#pattern0_13811_25198)" />
        </svg>
      </BrandLogo>
      <Container>
        <SidePanel>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.GIFT)}>Create Gift Codes</MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.AI_MESSENGER)}>AI Messenger</MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.PROMO)}>Create Promo Codes</MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.CHECK_REDEMPTIONS)}>
            Check Code Redemptions
          </MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.TEST_DIVERSIFIED_NOTIFICATIONS)}>
            Test Notifications
          </MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.MOBILE_CONFIG)}>
            View Mobile Config
          </MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.NOTIFICATION)}>
            Schedule Mass Notification
          </MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.UNSCHEDULE_NOTIFICATION)}>
            Unschedule Mass Notification
          </MenuItem>
          <MenuItem onClick={() => setCurrentAction(ACTIONS.EDIT_NOTIFICATIONS)}>
            Edit Mass Notification
          </MenuItem>
          <MenuItem
            onClick={() => {
              app.currentUser?.logOut();
              navigate('/admin/login');
              window.location.reload();
            }}>
            Logout
          </MenuItem>
        </SidePanel>
        <Content>{renderContent()}</Content>
      </Container>
    </Wrapper>
  );
};

export { AdminPortal };
